import { React, useEffect } from 'react';

const win = typeof window !== 'undefined' ? window : null;
const doc = typeof document !== 'undefined' ? document : null;

export const useLayoutTracking = () => {
  useEffect(() => {
    if (!win || !doc) return;

    (function (h, o, t, j, a, r) {
      h.hj =
        h.hj ||
        function () {
          (h.hj.q = h.hj.q || []).push(arguments);
        };
      h._hjSettings = { hjid: 3506436, hjsv: 6 };
      a = o.getElementsByTagName('head')[0];
      r = o.createElement('script');
      r.async = 1;
      r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
      a.appendChild(r);
    })(win, doc, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
  }, [win, doc]);
};
