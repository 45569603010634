import { Helmet } from 'react-helmet';
import OGImage from '../../images/favicon-black.png';
import '../../fonts/OpenSans/Regular/OpenSansRegular.woff';
import '../../fonts/OpenSans/Regular/OpenSansRegular.svg';
import '../../fonts/OpenSans/Regular/OpenSansRegular.eot';
import '../../fonts/OpenSans/Light/OpenSansLight.woff';
import '../../fonts/OpenSans/Light/OpenSansLight.svg';
import '../../fonts/OpenSans/Light/OpenSansLight.eot';
import '../../fonts/OpenSans/Bold/OpenSansBold.woff';
import '../../fonts/OpenSans/Bold/OpenSansBold.svg';
import '../../fonts/OpenSans/Bold/OpenSansBold.eot';
import React from 'react';
import { useLocation } from '@reach/router';

const LayoutHTMLHeader = ({ pageTitle = '', pageImage = false }) => {
  const location = useLocation();
  // console.log('LayoutHTMLHeader: location', location);
  const url = `${location.protocol}${location.host}`;
  const canonicalUrl = `${location.protocol}${location.host}${location.pathname}`;

  return (
    <Helmet>
      <link rel="canonical" href={canonicalUrl} />
      <meta http-equiv="Content-Type" content="text/xml; charset=UTF-8" />
      <meta property="og:title" content={`${pageTitle} | Girar World`} />
      <meta property="description" content="Girar, go on a journey" />
      <meta property="og:description" content="Girar, go on a journey" />
      <meta property="og:url" content={canonicalUrl} />
      <meta property="og:image" content={`${url}${OGImage}`} />
      <meta name="twitter:url" content={canonicalUrl} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@WeltgeistKiran" />
      <meta name="twitter:title" content={`${pageTitle} | Girar World`} />
      <meta name="twitter:description" content="Girar, go on a journey" />
      <meta property="twitter:image" content={pageImage ? pageImage : `${url}${OGImage}`} />
      <meta property="og:type" content="website" />
      <title> {pageTitle} | Girar World</title>
      <link rel="stylesheet" href="//cdn.quilljs.com/1.2.6/quill.snow.css" />
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
      <link
        href="https://fonts.googleapis.com/css2?family=Rokkitt:wght@400;500;600;700;800;900&family=Telex&display=swap"
        rel="stylesheet"
      />
    </Helmet>
  );
};

export default LayoutHTMLHeader;
