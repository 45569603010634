import React from 'react';
import { GlobalStyles } from 'twin.macro';
import { useLayoutTracking } from './LayoutTracking';
import LayoutHTMLHeader from './LayoutHTMLHeader';
import Footer from '@components/Footer';
import NavigationPrivate from '@components/navigation/NavigationPrivate';
import Navigation from '@components/navigation/Navigation';
import { useAuth } from '@contexts/MainAppContext';
import SpinnerDotPage from '@components/Spinners/SpinnerDotPage';

const Layout = ({ children, isLoading, showPrivateNavigation, ...rest }) => {
  const { isUserInformationLoading } = useAuth();
  useLayoutTracking();

  return (
    <div>
      <GlobalStyles />
      <LayoutHTMLHeader {...rest} />

      {isLoading === true || isUserInformationLoading ? (
        <SpinnerDotPage />
      ) : (
        <div className="overflow-hidden ">
          {showPrivateNavigation === true ? <NavigationPrivate /> : <Navigation />}
          {children}
          <Footer />
        </div>
      )}
    </div>
  );
};

export default Layout;
