import React, { useState, useRef } from 'react';
import { Link, navigate } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { useAuth } from '@contexts/MainAppContext';
import NavigationUserDropdown from './NavigationUserDropdown';
import BooksDropdown from '../dropdowns/BooksDropdown';
import StoriesDropdown from '../dropdowns/StoriesDropdown';
import { useGetBooks, useGetStories } from '@app/helper/services';

const NavigationPrivate = (props) => {
  const [bgNavbar] = useState('rgb(0,0,0,.9)');
  const [isNavOpen, setIsNavOpen] = useState(false);
  const { logoutUser, isUserPlanFree } = useAuth();
  const [isBooksDropdownOpen, setIsBooksDropdownOpen] = useState(false);
  const [isStoriesDropdownOpen, setIsStoriesDropdownOpen] = useState(false);
  const timeout = useRef(null);
  const { books } = useGetBooks(8);
  const { stories } = useGetStories(8);

  const menuOptions = [
    { name: 'Home', link: '/' },
    { name: 'Books', link: '/books' },
    { name: 'Stories', link: '/stories' },
    { name: 'Dashboard', link: '/dashboard' },
    { name: 'Profile', link: '/account' },
    { name: 'Manage Subscription', link: '/subscription' },
  ];

  const handleLogout = async (e) => {
    await logoutUser();
    navigate('/');
  };

  const handleMouseEnterBooks = () => {
    setIsBooksDropdownOpen(true);
    setIsStoriesDropdownOpen(false);
    if (timeout.current) clearTimeout(timeout.current);
  };

  const handleMouseLeaveBooks = () => {
    if (timeout.current) clearTimeout(timeout.current);
    timeout.current = setTimeout(() => {
      setIsBooksDropdownOpen(false);
    }, 400);
  };

  const handleMouseEnterStories = () => {
    setIsStoriesDropdownOpen(true);
    setIsBooksDropdownOpen(false);
    if (timeout.current) clearTimeout(timeout.current);
  };

  const handleMouseLeaveStories = () => {
    if (timeout.current) clearTimeout(timeout.current);
    timeout.current = setTimeout(() => {
      setIsStoriesDropdownOpen(false);
    }, 100);
  };

  return (
    <>
      <div
        className={`fixed w-screen xl:py-5 py-3 xl:bg-none z-10 xl:px-28 px-7 xl:bg-none xl:bg-opacity-0 navbar-bg-color`}
        style={{ backgroundColor: bgNavbar, transitionProperty: 'background-color', transitionDuration: '0.2s' }}>
        {/* hamburguer tailwind*/}
        <div className="flex items-center  py-0 justify-between">
          <div className="flex-none">
            <Link to="/" className="xl:block hidden">
              <StaticImage
                className="w-full xl:max-w-[130px] max-w-[100px] mx-auto xl:mt-1"
                src={'../../images/logo-white.png'}
                formats={['auto', 'webp', 'avif']}
                width={500}
                alt="Girar Logo"
                placeholder="blurred"
              />
            </Link>

            <Link to="/" className="xl:hidden block">
              <StaticImage
                className="w-full xl:max-w-[130px] max-w-[100px] mx-auto xl:mt-1"
                src={'../../images/logo-white.png'}
                formats={['auto', 'webp', 'avif']}
                width={500}
                alt="Girar Logo"
                placeholder="blurred"
              />
            </Link>
          </div>
          <nav className="flex-none lg:flex-grow ">
            <section
              className="MOBILE-MENU flex lg:hidden"
              onClick={() => setIsNavOpen((prev) => !prev)} // toggle isNavOpen state on click
            >
              {!isNavOpen ? (
                <div className="HAMBURGER-ICON space-y-2">
                  <span className="block h-0.5 w-8 animate-pulse bg-white"></span>
                  <span className="block h-0.5 w-8 animate-pulse bg-white"></span>
                  <span className="block h-0.5 w-8 animate-pulse bg-white"></span>
                </div>
              ) : null}
              <div className={isNavOpen ? 'showMenuNav bg-black' : 'hideMenuNav'}>
                <div className="CROSS-ICON absolute top-0 right-0 px-5 py-5" onClick={() => setIsNavOpen(false)} style={{ color: 'white' }}>
                  <svg
                    className="h-8 w-8 "
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round">
                    <line x1="18" y1="6" x2="6" y2="18" />
                    <line x1="6" y1="6" x2="18" y2="18" />
                  </svg>
                </div>
                {/*navbar links*/}
                <ul className="NAVIGATION-MOBILE-OPEN flex flex-col items-center justify-between min-h-[250px] ">
                  {menuOptions.map((option, index) => (
                    <li key={index} className="flex  align-items-center">
                      <Link to={option.link} className="navbar-link mx-auto">
                        {option.name}
                      </Link>
                    </li>
                  ))}
                  <li className="flex align-items-center ">
                    {isUserPlanFree ? (
                      <Link
                        className="xl:px-5 mx-auto px-5 button-white bottom-10 transition-all duration-500 btn-xs font-opensans"
                        to="/pricing-guide">
                        Upgrade Your Plan
                      </Link>
                    ) : null}{' '}
                  </li>
                  <li className="flex align-items-center pt-10">
                    <button
                      className="xl:px-5 mx-auto px-5 button-white bottom-10 transition-all duration-500 btn-xs font-bold font-opensans"
                      onClick={handleLogout}>
                      Logout
                    </button>
                  </li>
                </ul>
              </div>
            </section>

            <ul className="DESKTOP-MENU  hidden lg:flex justify-center">
              <li className="inline-block font-bold text-white  mx-4">
                <Link to="/">Home</Link>
              </li>
              <li className="inline-block font-bold text-white  mx-4">
                <Link to="/dashboard">Dashboard</Link>
              </li>
              <li
                className="dropdown-menu inline-block font-bold text-white  mx-4 flex justify-between"
                onMouseEnter={handleMouseEnterBooks}
                onMouseLeave={() => handleMouseLeaveBooks()}>
                <button className="dropdown-button">
                  <Link className="font-bold" to="/books">
                    Books{' '}
                  </Link>
                  <div className="ml-2 inline-block">
                    <i className="dropdown-chevron icon"></i>
                  </div>
                </button>
              </li>
              <li
                className="dropdown-menu inline-block font-bold text-white mx-4 flex justify-between"
                onMouseEnter={handleMouseEnterStories}
                onMouseLeave={handleMouseLeaveStories}>
                <button className="dropdown-button">
                  <Link className="font-bold" to="/stories">
                    Stories{' '}
                  </Link>
                  <div className="ml-2 inline-block">
                    <i className="dropdown-chevron icon"></i>
                  </div>
                </button>
              </li>
            </ul>
          </nav>
          <NavigationUserDropdown></NavigationUserDropdown>
        </div>
      </div>
      <div className="flex items-center justify-between py-0 dropdown-wide-container navbar-bg-black">
        {(isBooksDropdownOpen || false) && (
          <BooksDropdown books={books} handleMouseEnter={handleMouseEnterBooks} handleMouseLeave={handleMouseLeaveBooks} />
        )}
        {isStoriesDropdownOpen && (
          <StoriesDropdown books={stories} handleMouseEnter={handleMouseEnterStories} handleMouseLeave={handleMouseLeaveStories} />
        )}
      </div>
    </>
  );
};

export default NavigationPrivate;
