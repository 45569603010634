import React, { useState, useEffect } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { URL_ASSETS } from 'gatsby-env-variables';

const StoriesDropdown = ({ books, handleMouseEnter, handleMouseLeave }) => {
  return (
    <div className="w-screen w-100 navbar-bg-black" onMouseEnter={() => handleMouseEnter()} onMouseLeave={() => handleMouseLeave()}>
      <div className="dropdown-menu-content wide-dropdown w-screen w-100 block px-0 pb-5 flex flex-col xl:px-28 px-7 mt-[-5px]">
        <div className="grid grid-cols-4 gap-2 pb-5 bg-transparent">
          {books.map((item, index) => (
            <a
              key={index}
              className="rounded-xl w-100 flex flex-row text-white "
              href={item.chapters[0] ? `/chapter/?id=${item.chapters[0]?.id}&book=${item.id}` : '#'}>
              <img
                src={`${URL_ASSETS}${item.main_image_url}`}
                width={73}
                height={'73'}
                alt={item.name}
                className="mt-2 block rounded-xl megamenu-chapter-img flex-none"
              />
              <p className="block px-5 mt-2 flex-grow">{item.name}</p>
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

export default StoriesDropdown;
